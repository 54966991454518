import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/rgpd',
    name: 'RGPD',
    component: () => import('../views/RGPD.vue')
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import('../views/Legal.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: function(to) {
    if (to.hash) {
      return {el: to.hash}
    } else {
      return { top: 0 }
    }
  },
  routes
})

export default router

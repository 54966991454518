<template>
  <BrowserUpgrade/>
  <Preloader/>
  <Header/>
  <router-view/>
  <Footer/>
  <a href="#" class="scroll-top btn-hover">
    <i class="lni lni-chevron-up"></i>
  </a>
</template>

<script>
import Header from "./components/Header";
import Preloader from "./components/Preloader";
import BrowserUpgrade from "./components/BrowserUpgrade";
import Footer from "./components/Footer";
export default {
  components: {Footer, BrowserUpgrade, Preloader, Header}
}
</script>

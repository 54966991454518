<template>
  <div>
    <!--[if lte IE 9]>
    <p class="browserupgrade">
    You are using an <strong>outdated</strong> browser. Please
    <a href="https://browsehappy.com/">upgrade your browser</a> to improve
    your experience and security.
    </p>
    <![endif]-->
  </div>
</template>

<script>
export default {
  name: "BrowserUpgrade"
}
</script>

<style scoped>

</style>

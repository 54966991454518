<template>
  <footer class="footer mt-150 pt-90">
    <div class="container">
<!--      <div class="subscribe-wrapper">-->
<!--        <div class="row justify-content-center">-->
<!--          <div class="col-xl-6 col-lg-9">-->
<!--            <div class="section-title text-center mb-45">-->
<!--              <h1 class="mb-25 text-white wow fadeInUp" data-wow-delay=".2s">Subscribe Our Newsletter</h1>-->
<!--              <p class="text-white wow fadeInUp" data-wow-delay=".4s">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore</p>-->
<!--            </div>-->
<!--            <form action="#" class="subscribe-form wow fadeInUp" data-wow-delay=".6s">-->
<!--              <input type="email" name="subs-email" id="subs-email" placeholder="Your email">-->
<!--              <button type="submit" class="main-btn btn-hover">Subscribe Now</button>-->
<!--            </form>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="widget-wrapper">
        <div class="row">
<!--          <div class="col-lg-3 col-sm-6">-->
<!--            <div class="footer-widget">-->
<!--              <div class="logo">-->
<!--                <a href="index.html"> <img src="assets/img/logo/logo.svg" alt="logo"> </a>-->
<!--              </div>-->
<!--              <ul class="socials">-->
<!--                <li>-->
<!--                  <a href="javascript:void(0)"> <i class="lni lni-facebook-filled"></i> </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="javascript:void(0)"> <i class="lni lni-twitter-filled"></i> </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="javascript:void(0)"> <i class="lni lni-instagram-filled"></i> </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="javascript:void(0)"> <i class="lni lni-linkedin-original"></i> </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h3 class="text-white">Explorer</h3>
              <ul class="links">
                <li>
                  <router-link to="/#features" class="page-scroll">Fonctionnalités</router-link>
                </li>
                <li>
                  <router-link to="/#about" class="page-scroll">À savoir</router-link>
                </li>
                <li>
                  <router-link to="/#pricing" class="page-scroll">Tarifs</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h3 class="text-white">Légal</h3>
              <ul class="links">
                <li>
                  <router-link to="/legal">Mentions légales</router-link>
                </li>
                <li>
                  <router-link to="/rgpd">RGPD</router-link>
                </li>
<!--                <li>-->
<!--                  <a href="javascript:void(0)">Terms of Service</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="javascript:void(0)">Support Policy</a>-->
<!--                </li>-->
              </ul>
            </div>
          </div>
<!--          <div class="col-lg-3 col-sm-6">-->
<!--            <div class="footer-widget">-->
<!--              <h3 class="text-white">Links</h3>-->
<!--              <ul class="links">-->
<!--                <li>-->
<!--                  <a href="javascript:void(0)">Help </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="javascript:void(0)">Support </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="javascript:void(0)">Contact </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>

<template>
  <header class="header">
    <div class="navbar-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
<!--              <a class="navbar-brand" href="index.html">-->
<!--                <img src="assets/img/logo/logo.svg" alt="Logo" />-->
<!--              </a>-->
              <h1 class="text-black-50 w-100 font-weight-bold">WHAT A FIX</h1>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul id="nav" class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <router-link to="/#home" class="page-scroll">Accueil</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/#features" class="page-scroll">Fonctionnalités</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/#about" class="page-scroll">À savoir</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/#pricing" class="page-scroll">Tarifs</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/#contact" class="page-scroll">Contact</router-link>
                  </li>
                </ul>
              </div>
              <!-- navbar collapse -->
            </nav>
            <!-- navbar -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!-- navbar area -->
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>
